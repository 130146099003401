import React from 'react';
import PropTypes from 'prop-types';

import { Img } from 'utilities/components/img/component';

import residence from 'assets/images/contact/residence.svg';
import bill from 'assets/images/contact/bill.svg';

import './view.scss';

export const DetailsView = ({ data }) => (
    <section className="details">
        <div className="details__detail">
            <h4 className="detail__headline">Nasza siedziba</h4>
            <div className="detail__container">
                <Img className="detail__icon" src={ residence } alt="siedziba" />
                <div>
                    <p className="detail__description">
                        <strong>{ data?.company_name }</strong>
                        <br />
                        { data?.address_line_1 }
                        <br />
                        { data?.address_line_2 }
                    </p>
                    <p className="detail__description detail__description--accent">
                        <strong>Godziny pracy:</strong>
                        <br />
                        { data?.working_days }
                        { ' ' }
                        <strong>{ data?.working_hours }</strong>
                    </p>
                </div>
            </div>
        </div>
        <div className="details__detail">
            <h4 className="detail__headline">Dane do faktury</h4>
            <div className="detail__container">
                <Img className="detail__icon" src={ bill } alt="faktura" />
                <div>
                    <p className="detail__description">
                        { data?.invoice_address_line_1 }
                        <br />
                        { data?.invoice_address_line_2 }
                        <br />
                        <strong>NIP:</strong>
                        { ' ' }
                        { data?.nip }
                        <br />
                        <strong>KRS:</strong>
                        { ' ' }
                        { data?.krs }
                        <br />
                        <strong>REGON:</strong>
                        { ' ' }
                        { data?.regon }
                    </p>
                    <p className="detail__description detail__description--accent">
                        <strong>Kontakt:</strong>
                        <br />
                        E-mail:
                        { ' ' }
                        <a href={ `mailto:${data?.email}` }>
                            { data?.email }
                        </a>
                        <br />
                        Telefon:
                        { ' ' }
                        <a href={ `tel:${data?.phone_number_1}` }>
                            { data?.phone_number_1 }
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </section>
);

DetailsView.propTypes = {
    data: PropTypes.instanceOf(Object).isRequired
};