import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { DetailsView } from './view';

export const Details = () => {
    const data = useStaticQuery(graphql`
        query {
            allContacts(filter: {
                is_active: {
                    eq: 1
                }
            }) {
                nodes {
                    company_name
                    address_line_1
                    address_line_2
                    working_days
                    working_hours
                    invoice_address_line_1
                    invoice_address_line_2
                    nip
                    krs
                    regon
                    email
                    phone_number_1
                }
            }
        }
    `);

    return (<DetailsView data={ data?.allContacts?.nodes[0] } />);
};