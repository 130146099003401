import React from 'react';
import PropTypes from 'prop-types';

import { Img } from 'utilities/components/img/component';

import consultant from 'assets/images/contact/consultant.svg';
import protection from 'assets/images/contact/protection.svg';
import document from 'assets/images/contact/document.svg';

import './view.scss';

export const DepartmentsView = ({ data }) => (
    <section className="departments">
        <div className="departments__container">
            <div className="departments__department">
                <Img className="department__icon" src={ consultant } alt="konsultant" />
                <h4 className="department__headline">Biuro Obsługi Klienta</h4>
                <p className="department__description">
                    E-mail:
                    { ' ' }
                    <a href={ `mailto:${data?.customer_service_email}` }>
                        { data?.customer_service_email }
                    </a>
                    <br />
                    Telefon:
                    { ' ' }
                    <a href={ `tel:${data?.customer_service_phone}` }>
                        { data?.customer_service_phone }
                    </a>
                </p>
            </div>
            <div className="departments__department">
                <Img className="department__icon" src={ protection } alt="ochrona" />
                <h4 className="department__headline">Serwis i reklamacje</h4>
                <p className="department__description">
                    E-mail:
                    { ' ' }
                    <a href={ `mailto:${data?.complains_service_email}` }>
                        { data?.complains_service_email }
                    </a>
                    <br />
                    Telefon:
                    { ' ' }
                    <a href={ `tel:${data?.complains_service_phone}` }>
                        { data?.complains_service_phone }
                    </a>
                </p>
            </div>
            <div className="departments__department">
                <Img className="department__icon" src={ document } alt="dokument" />
                <h4 className="department__headline">Księgowość</h4>
                <p className="department__description">
                    E-mail:
                    { ' ' }
                    <a href={ `mailto:${data?.accountant_service_email}` }>
                        { data?.accountant_service_email }
                    </a>
                    <br />
                    Telefon:
                    { ' ' }
                    <a href={ `tel:${data?.accountant_service_phone}` }>
                        { data?.accountant_service_phone }
                    </a>
                </p>
            </div>
        </div>
    </section>
);

DepartmentsView.propTypes = {
    data: PropTypes.instanceOf(Object).isRequired
};