import React from 'react';
import PropTypes from 'prop-types';

import { HeadlineView } from 'utilities/components/headline/component/view';
import { Img } from 'utilities/components/img/component';

import map from 'assets/images/contact/map.png';

import './view.scss';

export const CollaborationView = ({ data }) => (
    <section className="collaboration">
        <HeadlineView text="Współpraca i handel" />
        <div className="collaboration__container">
            <Img className="collaboration__image" src={ map } alt="mapa" />
            <div>
                <h4 className="collaboration__headline">Region północ</h4>
                <p className="collaboration__description collaboration__description--large">{ data?.north_name_surname }</p>
                <p className="collaboration__description">
                    { data?.north_region_position }
                    <br />
                    <br />
                    Telefon:
                    { ' ' }
                    <a href={ `tel:${data?.north_region_phone}` }>
                        { data?.north_region_phone }
                    </a>
                    <br />
                    E-mail:
                    { ' ' }
                    <a href={ `mailto:${data?.north_region_email}` }>
                        { data?.north_region_email }
                    </a>
                </p>
                <h4 className="collaboration__headline">Region południe</h4>
                <p className="collaboration__description collaboration__description--large">{ data?.south_name_surname }</p>
                <p className="collaboration__description">
                    { data?.south_region_position }
                    <br />
                    <br />
                    Telefon:
                    { ' ' }
                    <a href={ `tel:${data?.south_region_phone}` }>
                        { data?.south_region_phone }
                    </a>
                    <br />
                    E-mail:
                    { ' ' }
                    <a href={ `mailto:${data?.south_region_email}` }>
                        { data?.south_region_email }
                    </a>
                </p>
            </div>
        </div>
    </section>
);

CollaborationView.propTypes = {
    data: PropTypes.instanceOf(Object).isRequired
};