import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';

import { Navigation } from '../utilities/components/navigation/component';
import { BannerView } from '../utilities/components/banner/component/view';
import { Details } from '../features/contact/details/component';
import { Departments } from '../features/contact/departments/component';
import { Collaboration } from '../features/contact/collaboration/component';
import { Footer } from '../utilities/components/footer/component';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import SEO from 'utilities/components/seo';

import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { routes } from 'utilities/routes';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';
import { SeoDecription } from 'features/index/seo-description/view';

const Contact = () => {
    const data = useStaticQuery(graphql`
        query GetContactData {
            banners(site_type: { eq: "Kontakty" }) {
                image
                alt_image
                background_position_x
            }
        }
    `);

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };

    const breadcrumbs = [routes.home, routes.kontakt];
    const { metaTitle, metaDescription, description } = useSeoTexts('Kontakt');

    return (
        <main className="contact">
            <SEO
                title={ metaTitle || 'Barański Drzwi - Kontakt - Biuro Obsługi Klienta' }
                description={
                    metaDescription
                    || 'Biuro Obsługi Klienta, Serwis i reklamacje, Księgowość - dane kontaktowe i adres siedziby spółki.'
                }
                image={ `${process.env.GATSBY_URL}/${data?.banners?.image}` }
                alt={ data?.banners?.alt_image }
                canonicalLink={ setCanonicalLink() }
            />
            <Helmet>
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "http://schema.org",
                            "@type": "HomeAndConstructionBusiness",
                            "@id": "https://www.baranskidrzwi.pl",
                            "url": "https://www.baranskidrzwi.pl",
                            "name": "Producent Drzwi Barański",
                            "description": "Tworzymy wysokiej jakości drzwi wejściowe, pokojowe i łazienkowe. Sprawdź naszą ofertę drzwi klasycznych i nowoczesnych do domu i mieszkania!",
                            "priceRange": "$$-$$$",
                            "telephone": "+48 55 278 05 40",
                            "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Babięty Wielkie 54",
                            "addressLocality": "Babięty Wielkie",
                            "postalCode": "14-240",
                            "addressCountry": "Poland"
                            },
                            "openingHoursSpecification": [
                            {
                                "@type": "OpeningHoursSpecification",
                                "dayOfWeek": [
                                    "Monday",
                                    "Tuesday",
                                    "Wednesday",
                                    "Thursday",
                                    "Friday"
                                ],
                                "opens": "07:00",
                                "closes": "17:00"
                            }
                            ],
                            "sameAs": ["https://www.facebook.com/baranskidrzwi"]
                        }
                    `}
                </script>
            </Helmet>
            <Navigation />
            <BannerView
                image={ `${process.env.GATSBY_URL}/${data?.banners?.image}` }
                headline="Kontakt"
                x={ data?.banners?.background_position_x }
            />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <Details />
            <Departments />
            <Collaboration />
            <SeoDecription seoDescription={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Contact;
