import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { CollaborationView } from './view';

export const Collaboration = () => {
    const data = useStaticQuery(graphql`
        query {
            allContacts(filter: {
                is_active: {
                    eq: 1
                }
            }) {
                nodes {
                    north_name_surname
                    north_region_position
                    north_region_phone
                    north_region_email
                    south_name_surname
                    south_region_position
                    south_region_phone
                    south_region_email
                    is_active
                }
            }
        }
    `);

    return (<CollaborationView data={ data?.allContacts?.nodes[0] } />);
};