import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { DepartmentsView } from './view';

export const Departments = () => {
    const data = useStaticQuery(graphql`
        query {
            allContacts(filter: {
                is_active: {
                    eq: 1
                }
            }) {
                nodes {
                    customer_service_email
                    customer_service_phone
                    complains_service_email
                    complains_service_phone
                    accountant_service_email
                    accountant_service_phone
                }
            }
        }
    `);

    return (<DepartmentsView data={ data?.allContacts?.nodes[0] } />);
};